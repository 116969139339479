export interface BlogState {
  loading: boolean;
  search: string;
  categories: string[];
  activeCategories: string[];
  lastEntry: SingleStory | null;
  entries: SingleStory[];
  total: number;
}

const initialState = {
  loading: true,
  entries: [],
  lastEntry: null,
  search: "",
  categories: [],
  activeCategories: [],
  total: 0
};

export interface SingleStory {
  content: {
    category: string[];
    content: string;
    excerpt: string;
    title: string;
    image: string;
  };
  position: number;
  created_at: Date | string;
  published_at: Date | string;
  id: number;
  name: string;
  slug: string;
  translated_slugs: {
    lang: string;
    name: string;
    path: string;
  }[];
}

interface loadingEntries {
  type: "LOADING_ENTRIES";
  payload: boolean;
}
interface getEntries {
  type: "GET_ENTRIES";
  payload: SingleStory[];
}

interface getLastEntry {
  type: "GET_LAST_ENTRY";
  payload: SingleStory;
}

interface getBlogTotal {
  type: "GET_BLOG_TOTAL";
  payload: number;
}

type Action = loadingEntries | getEntries | getLastEntry | getBlogTotal;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case "LOADING_ENTRIES":
      return { ...state, loading: action.payload };
    case "GET_LAST_ENTRY":
      return { ...state, lastEntry: action.payload };
    case "GET_BLOG_TOTAL":
      return { ...state, total: action.payload };
    case "GET_ENTRIES":
      const categories = ([] as string[])
        .concat(
          ...action.payload.map((story: SingleStory) => story.content.category)
        )
        .filter((item, pos, self) => self.indexOf(item) === pos);
      return Object.assign({}, state, {
        entries: action.payload,
        loading: false,
        search: "",
        categories: categories,
        activeCategories: categories
      });
    //     case "SEARCH_BLOG":
    //       return Object.assign({}, state, {
    //         search: action.payload
    //       });
    //     case "TOGGLE_CATEGORY":
    //       const index = state.activeCategories.indexOf(action.payload);
    //       const activeCategories =
    //         index >= 0
    //           ? [
    //               ...state.activeCategories.slice(0, index),
    //               ...state.activeCategories.slice(index + 1)
    //             ]
    //           : [...state.activeCategories, action.payload];
    //       return Object.assign({}, state, {
    //         activeCategories
    //       });
  }

  return state;
};
