import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";

interface Props {
  pageCount: number;
  initialPage?: number;
  onPageChange?: (page: number) => void;
  currentPage: number;
}
const Pagination = ({
  pageCount,
  initialPage,
  onPageChange,
  currentPage,
}: Props) => {
  if (pageCount < 2) return null;
  return (
    <ReactPaginate
      containerClassName="pagination__bar"
      // pageLinkClassName="pagination__bar-btn"
      pageLinkClassName="pagination__bar-btn"
      activeLinkClassName="pagination__bar-btn--active"
      previousLinkClassName="pagination__bar-prev-btn pagination__bar-btn"
      nextLinkClassName="pagination__bar-next-btn pagination__bar-btn"
      disabledClassName="button-link disabled"
      disableInitialCallback={true}
      onPageChange={({ selected }: { selected: number }) => {
        console.log("selected", selected);
        onPageChange && onPageChange(selected + 1);
      }}
      breakLinkClassName="pagination__bar__number-btn pagination__bar-btn"
      previousLabel={"<"}
      nextLabel={">"}
      // initialPage={(initialPage || 1) - 1}
      pageCount={pageCount}
      pageRangeDisplayed={2}
      marginPagesDisplayed={1}
      forcePage={currentPage - 1}
    />
  );
};
export default Pagination;
