import React from "react";
import { IconHeart } from "../../Common/SVG-icons";
import LocalizedLink from "components/Common/LocalizedLink";
import { __ } from "helpers/i18n";

const Footer = () => {
  return (
    <>
      <div className="footer ">
        <div className="footer__background" />
        <div className="footer__paragraph">
          {/* <span> */}
          IT.focus, from Internet with <IconHeart className="footer__icon" />
          {/* </span> */}
          <span className="footer__border">/</span>
          <LocalizedLink
            className="footer__privacy"
            to={`/${__("paths.privacy-policy")}`}
          >
            {__("privacy-policy")}
          </LocalizedLink>
        </div>
      </div>
    </>
  );
};

export default Footer;
