import React from "react";
import { __ } from "helpers/i18n";
import {
  IconSpeechBubble,
  IconCode,
  IconPicture
} from "components/Common/SVG-icons";
import { DecorationDotProps } from "components/Common/DecorationDot";
import { DecorationDot, Header, Paragraph, List } from "components/Common";
import Section from "components/Layout/Section";

const dotsArr: Array<DecorationDotProps> = [
  {
    color: "accent-blue",
    style: { width: "5vw", height: "5vw", top: "-2.5vw", left: "-2.5vw" }
  },
  {
    color: "accent-green",
    style: { width: "1vw", height: "1vw", top: "-0.5vw", right: "20vw" }
  },
  {
    color: "accent-blue",
    style: { width: "1.5vw", height: "1.5vw", bottom: "20vw", left: "15vw" }
  },
  {
    color: "accent-green",
    style: { width: "2.5vw", height: "2.5vw", top: "40vw", right: "18vw" }
  },
  {
    color: "accent-green",
    style: { width: "8vw", height: "8vw", bottom: "20vw", right: "-4vw" }
  }
];

const LandingOurWork = () => {
  return (
    <Section center className="landing-our-work">
      <div className="landing-our-work__decoration-dots-group">
        {dotsArr.map((item, index) => (
          <DecorationDot
            key={`decortion-dot-${index}`}
            color={item.color}
            style={item.style}
          />
        ))}
      </div>
      <Header center>
        {__("landing.sections.our-work.header.0")}
        {__("landing.sections.our-work.header.1")}
      </Header>
      <div className="landing-our-work__container-top">
        <div className="landing-our-work__container-top__item">
          <div className="landing-our-work__container-top__item-background">
            <IconSpeechBubble className="landing-our-work__container-top__item-icon" />
          </div>
          <Header
            type="h3"
            className="landing-our-work__item-header"
            center
            text="landing.sections.our-work.conversation"
          />
        </div>
        <div className="landing-our-work__container-top__item">
          <div className="landing-our-work__container-top__item-background">
            <IconCode className="landing-our-work__container-top__item-icon" />
          </div>
          <Header
            type="h3"
            className="landing-our-work__item-header"
            center
            text="landing.sections.our-work.work"
          />
        </div>
        <div className="landing-our-work__container-top__item">
          <div className="landing-our-work__container-top__item-background">
            <IconPicture className="landing-our-work__container-top__item-icon" />
          </div>
          <Header
            type="h3"
            className="landing-our-work__item-header"
            center
            text="landing.sections.our-work.presentation"
          />
        </div>
      </div>
      <Paragraph text="landing.sections.our-work.paragraph.0" />

      <List>
        <li>
          <Paragraph text="landing.sections.our-work.list.0" />
        </li>
        <li>
          <Paragraph text="landing.sections.our-work.list.1" />
        </li>
        <li>
          <Paragraph text="landing.sections.our-work.list.2" />
        </li>
      </List>

      <Paragraph text="landing.sections.our-work.paragraph.1" />
    </Section>
  );
};

export default LandingOurWork;
