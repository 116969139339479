import { combineReducers } from "redux";
import langReducer from "./lang-reducer";
import appStatusReducer from "./app-status-reducer";
import blogReducer, { BlogState } from "reducers/blog-reducer";
import entryReducer, { EntryState } from "reducers/entry-reducer";
import portfolioReducer, { PortfolioState } from "reducers/portfolio-reducer";
import portfolioEntryReducer, {
  PortfolioEntryState
} from "reducers/portfolio-entry-reducer";

export interface ApplicationState {
  blog: BlogState;
  portfolio: PortfolioState;
  portfolioEntry: PortfolioEntryState;
  entry: EntryState;
  appStatus: any;
  lang: any;
}

const appReducer = combineReducers({
  lang: langReducer,
  appStatus: appStatusReducer,
  blog: blogReducer,
  portfolio: portfolioReducer,
  portfolioEntry: portfolioEntryReducer,
  entry: entryReducer
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

export default rootReducer;
