import React from "react";

export interface DecorationDotProps {
  className?: string;
  color?: string;
  style: object;
}

const DecorationDot = (props: DecorationDotProps) => {
  const { className, style, color } = props;

  return (
    <div className={`decoration-dot ${color} ${className}`} style={style} />
  );
};

export default DecorationDot;
