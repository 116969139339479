import StoryblokClient from "storyblok-js-client";
import { SingleStory } from "reducers/blog-reducer";

const Storyblok = new StoryblokClient({
  oauthToken: "sbjRrFLDSdfYX2rsMlQTRgtt",
  accessToken: "sbjRrFLDSdfYX2rsMlQTRgtt",
  cache: {
    clear: "auto",
    type: "memory"
  }
});

const translatePost = (post: SingleStory, lang: string) => {
  let newPost = { ...post };

  const translation = post.translated_slugs.find(t => t.lang === lang);

  if (translation) {
    newPost.slug = translation.path;
    newPost.name = translation.name;
  }

  return newPost;
};

export const getPortfolioEntries = (page: number, lang: string) => {
  return (dispatch: any) => {
    dispatch({ type: "LOADING_PORTFOLIO_ENTRIES", payload: true });
    Storyblok.get(`cdn/stories`, {
      per_page: page === 12,
      page,
      starts_with: lang === "pl" ? "" : `${lang}/*`
    })
      .then(response => {
        const stories = response.data.stories;
        dispatch({ type: "GET_BLOG_TOTAL", payload: response.headers.total });

        dispatch({
          type: "GET_PORTFOLIO_ENTRIES",
          payload: stories.map((s: SingleStory) =>
            lang === "pl" ? s : translatePost(s, lang)
          )
        });
        dispatch({ type: "LOADING_PORTFOLIO_ENTRIES", payload: false });
      })

      .catch(error => {
        dispatch({ type: "GET_PORTFOLIO_ENTRIES", payload: [] });
        dispatch({ type: "LOADING_PORTFOLIO_ENTRIES", payload: false });
      });
  };
};

export const getPortfolioEntry = (slug: string, lang: string) => (
  dispatch: any
) => {
  dispatch({ type: "LOADING_PORTFOLIO_ENTRY", payload: true });
  Storyblok.get(
    lang === "pl" ? `cdn/stories/${slug}` : `cdn/stories/${lang}/${slug}`,
    {
      // sstarts_with: lang === "pl" ? "" : `${lang}/*`
    }
  )
    .then(response => {
      const { story } = response.data;
      dispatch({
        type: "GET_PORTFOLIO_ENTRY",
        payload: lang === "pl" ? story : translatePost(story, lang)
      });
      dispatch({ type: "LOADING_PORTFOLIO_ENTRY", payload: false });
    })
    .catch(error => {
      dispatch({ type: "PORTFOLIO_ENTRY_NOT_FOUND" });
      dispatch({ type: "LOADING_PORTFOLIO_ENTRY", payload: false });
    });
};

export const clearPortfolioEntry = () => ({ type: "CLEAR_PORTFOLIO_ENTRY" });
