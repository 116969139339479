import React from "react";
import Container from "components/Layout/Container";

interface Props {
  children: React.ReactNode;
  className?: string;
  light?: boolean;
  dark?: boolean;
  center?: boolean;
}

const Section = ({ children, className, light, dark, center }: Props) => (
  <section
    className={`section-wrapper ${className || ""} ${light ? "light" : ""} ${
      dark ? "dark" : ""
    }`}
  >
    <Container center={center}>{children}</Container>
  </section>
);

export default Section;
