import React, { useState } from "react";
import { i18n } from "helpers/i18n";

import arrow from "assets/icons-svg/small_arrow.svg";
import { useDispatch } from "react-redux";
import { changeLanguage } from "actions";

interface Props {
  className?: string;
}

const LangButton = (props: Props) => {
  const [isActive, setIsActive] = useState(false);
  const { className } = props;
  const dispatch = useDispatch();
  return (
    <div
      className={`lang-button ${className}`}
      onClick={() => setIsActive(!isActive)}
    >
      <div
        className={`lang-button__select ${
          isActive
            ? "lang-button__select--active"
            : "lang-button__select--hidden"
        }`}
      >
        <div className="lang-button__select-text">{i18n.currentLang}</div>
        <div className="lang-button__select-arrow">
          <img src={arrow} alt="" />
        </div>
      </div>

      <div
        className={`lang-button__list ${
          isActive ? "lang-button__list--active" : "lang-button__list--hidden"
        }`}
      >
        <div
          className="lang-button__list-option"
          // onClick={() => i18n.changeLang("pl")
          onClick={() => dispatch(changeLanguage("pl"))}
        >
          PL
        </div>
        <div
          className="lang-button__list-option"
          // onClick={() => i18n.changeLang("en")}
          onClick={() => dispatch(changeLanguage("en"))}
        >
          EN
        </div>
      </div>
    </div>
  );
};

export default LangButton;
