import React, { useState, useEffect } from "react";
import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import reduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import reducers from "./reducers";
import { i18n } from "./helpers/i18n";
import {
  BottomCTA,
  Spinner,
  Newsletter,
  ScrollTopButton
} from "./components/Common";
import { Navbar, Main, Footer } from "./components/Layout";
import Preloader from "components/Common/Preloader";
import { appStatus } from "./actions/app-status";
import { scrollTop } from "./utils/scroll-top";
import { __ } from "helpers/i18n";
import Routing from "components/Routing";

const createStoreWithMiddleware = composeWithDevTools(
  applyMiddleware(reduxThunk)
)(createStore);

export const store = createStoreWithMiddleware(reducers);

export const history = createBrowserHistory({ basename: "/" });

const App = () => {
  const [loading, setLoading] = useState(true);

  const checkI18nLoaded = () => {
    if (i18n.loaded) {
      setLoading(false);
    } else {
      setTimeout(checkI18nLoaded, 100);
    }
  };

  useEffect(() => {
    const lang = store.getState().lang.current;
    i18n.load(lang);
    checkI18nLoaded();
  }, []);

  history.listen(location => {
    scrollTop();
  });

  if (loading) return <Spinner loading visible mode="full" />;

  return (
    <Provider store={store}>
      <Router history={history}>
        <Navbar />
        {/* <BottomCTA /> */}
        {/* <Newsletter className="section-wrapper" /> */}
        <Main>
          <Preloader />
          <Routing />
        </Main>
        <Footer />
      </Router>
      <ScrollTopButton />
    </Provider>
  );
};

const listenToWindowEvent = (name: string, mapEventToAction: any) => (
  dispatch: any
) => {
  const handleEvent = (e: any) => {
    dispatch(mapEventToAction(e));
  };
  window.addEventListener(name, handleEvent);
};

store.dispatch(listenToWindowEvent("offline", appStatus));

store.dispatch(listenToWindowEvent("online", appStatus));

store.dispatch(
  appStatus({ type: window.navigator.onLine ? "online" : "offline" })
);

export default App;
