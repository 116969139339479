import { LANGUAGES, DEFAULT_LANG } from "config/app";

export default class SessionService {
  static getCurrentLanguage() {
    const language = window.location.pathname.split("/")[1].toLowerCase();
    if (language !== undefined)
      if (LANGUAGES.find(lang => lang.shortcut === language)) return language;

    return DEFAULT_LANG.shortcut;
  }
}
