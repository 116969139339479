import React, { useState } from "react";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";

interface Props {
  className?: String;
  header: String;
}

const FilterButton = (props: Props) => {
  const { className, header } = props;

  const [checked, setChecked] = useState(false);

  return (
    <button
      className={`filter-button ${className}`}
      onClick={() => setChecked(!checked)}
    >
      <div className="filter-button__checkmark">
        <div className="filter-button__overlay" />
        <CheckOutlinedIcon
          className={`filter-button__checkmark-icon ${
            checked ? "filter-button__checkmark-icon--active" : ""
          }
          `}
        />
      </div>
      <span className="filter-button__text">{header}</span>
    </button>
  );
};

export default FilterButton;
