import React from "react";
import ImageBackground from "assets/background-shape-semi-transparent-right.svg";
import LocalizedLink from "../LocalizedLink";
import { Header } from "components/Common";

interface Props {
  className?: String;
  img: any;
  link: string;
  header: String;
  paragraph: String;
  background?: Boolean;
}

const BigCircleTab = (props: Props) => {
  const {
    className = "",
    link,
    img,
    header,
    paragraph,
    background = false
  } = props;

  return (
    <LocalizedLink to={link} className={`big-circle-tab ${className}`}>
      <div className="big-circle-tab__wrapper">
        {background ? (
          <img
            src={ImageBackground}
            alt=""
            className="big-circle-tab__background"
          />
        ) : (
          ""
        )}
        <img src={img} alt="" className="big-circle-tab__img" />
      </div>
      <Header type="h3" className="big-circle-tab__header">
        {header}
      </Header>
      <p className="big-circle-tab__paragraph font-weight-medium paragraph-2 paragraph paragraph-color-dark-blue">
        {paragraph}
      </p>
    </LocalizedLink>
  );
};

export default BigCircleTab;
