import React from "react";
import ReactMarkdown from "react-markdown";
import { useHistory } from "react-router-dom";
import { SingleStory } from "reducers/blog-reducer";
import LinkToYTTransformer from "components/Common/LinkToYt";
import { format } from "date-fns";
import { i18n, __ } from "helpers/i18n";
import Button from "components/Common/Button";
import { Header } from "components/Common";
import { ButtonsContainer } from "components/Layout";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

interface Props {
  entry: SingleStory;
  className?: String;
}

const RecentPost = (props: Props) => {
  const { className = "", entry } = props;
  const history = useHistory();

  const redirect = () => {
    history.push(`/${i18n.currentLang}/blog/${entry.slug}`);
  };

  return (
    <div className={`recent-post ${className}`} onClick={() => redirect()}>
      <Header type="h2" center>
        {__("recent_post")}
      </Header>
      <div
        className="recent-post__background"
        style={{ backgroundImage: `url(${entry.content.image})` }}
      >
        <div className="recent-post__background-overlay" />
        <div className="recent-post__text-wrapper">
          <div className="recent-post__info">
            <div className="recent-post__info__date">
              <strong>
                {format(new Date(entry.published_at), "dd.MM.yyyy")}
              </strong>
            </div>
            <div className="recent-post__info__border">|</div>
            <div className="recent-post__info__category">
              {entry.content.category.join(", ")}
            </div>
          </div>
          <Header type="h3" big>
            {entry.name}
          </Header>
          <div className="recent-post__paragraph paragraph">
            <ReactMarkdown
              escapeHtml={false}
              source={entry.content.excerpt}
              renderers={{
                link: LinkToYTTransformer
              }}
            />
          </div>
        </div>
        <ButtonsContainer end>
          <Button
            big
            primary
            text="read_more"
            icon={faArrowRight}
            to={`/${i18n.currentLang}/blog/${entry.slug}`}
          />
        </ButtonsContainer>
      </div>
    </div>
  );
};

export default RecentPost;
