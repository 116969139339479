import React, { useState } from "react";
import { Header } from "components/Common";

interface Props {
  className: string;
  data: {
    header: string;
    description: string;
    photo?: string;
    author: string;
  }[];
}

const Carousel = (props: Props) => {
  const { className, data } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={`carousel ${className || ""}`}>
      {data.map((item, index) => (
        <div
          key={`carousel-item-${index}`}
          className={`carousel__wrapper carousel__content ${
            index === activeIndex
              ? "carousel__content--active"
              : "carousel__content--hidden"
          }`}
        >
          <Header type="h3">{item.header}</Header>

          <div className="carousel__content__quote">
            <blockquote className="carousel__content__quote-description block-quote paragraph-1">
              {item.description}
            </blockquote>
            <p className="carousel__content__quote-author block-quote font-weight-bold paragraph-1">
              <strong>{item.author}</strong>
            </p>
          </div>
        </div>
      ))}
      <div className="carousel__wrapper carousel__customer">
        <div className="carousel__customer-background">
          {data.map((item, index) =>
            item.photo ? (
              <div
                className={`carousel__image ${
                  index === activeIndex
                    ? "carousel__image--active"
                    : "carousel__image--hidden"
                }`}
              >
                <img
                  key={`image-${index}`}
                  className={`carousel__customer-photo`}
                  src={`${item.photo}`}
                  alt=""
                />
              </div>
            ) : (
              <div />
            )
          )}
        </div>
        <div className="carousel__dots">
          {data.map((item, index) => (
            <div
              key={`dot-${index}`}
              className={`carousel__dots-item  ${
                index === activeIndex ? "carousel__dots-item--active" : ""
              }`}
              onClick={e => setActiveIndex(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
