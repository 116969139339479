import { SinglePortfolioItem } from "reducers/portfolio-reducer";

export interface PortfolioEntryState {
  loading: boolean;
  content: SinglePortfolioItem | null;
}

const initialState = {
  loading: true,
  content: null
};

interface loadingPortfolioEntry {
  type: "LOADING_PORTFOLIO_ENTRY";
  payload: boolean;
}
interface getPortfolioEntry {
  type: "GET_PORTFOLIO_ENTRY";
  payload: SinglePortfolioItem;
}
interface clearPortfolioEntry {
  type: "CLEAR_PORTFOLIO_ENTRY";
}

type Action = getPortfolioEntry | loadingPortfolioEntry | clearPortfolioEntry;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case "LOADING_PORTFOLIO_ENTRY":
      return { ...state, loading: action.payload };
    case "GET_PORTFOLIO_ENTRY":
      return { ...state, content: action.payload };
    case "CLEAR_PORTFOLIO_ENTRY":
      return initialState;
  }

  return state;
};
