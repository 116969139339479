import React, { useEffect, Suspense } from "react";

import { Switch, Route, Redirect, useLocation } from "react-router";
import { DEFAULT_LANG } from "config/app";
import { useSelector } from "react-redux";
import { ApplicationState } from "reducers";
import { history } from "App";
import { Spinner } from "components/Common";

interface NavigationRoute {
  component: React.ComponentClass | React.FunctionComponent;
  exact?: boolean;
  [key: string]: string | any;
}

const Home = React.lazy(() => import("../../views/Home"));
const Offer = React.lazy(() => import("../../views/Offer"));
const Achievements = React.lazy(() => import("../../views/Achievements"));
const Benefits = React.lazy(() => import("../../views/Benefits"));
const HowWeWork = React.lazy(() => import("../../views/HowWeWork"));
const Blog = React.lazy(() => import("../../views/Blog"));
const Post = React.lazy(() => import("../../views/Post"));
const Portfolio = React.lazy(() => import("../../views/Portfolio"));
const Project = React.lazy(() => import("../../views/Project"));
const Landing = React.lazy(() => import("../../views/Landing"));
const Workshops = React.lazy(() => import("../../views/Workshops"));
const Contact = React.lazy(() => import("../../views/Contact"));
const Page404 = React.lazy(() => import("../../views/Page404"));
const PrivacyPolicy = React.lazy(() => import("../../views/PrivacyPolicy"));

const routes: NavigationRoute[] = [
  {
    pl: "/pl/o-nas",
    en: "/en/about-us",
    component: Home
  },
  {
    pl: "/pl/oferta",
    en: "/en/offer",
    component: Offer
  },
  {
    pl: "/pl/nasze-sukcesy",
    en: "/en/achievements",
    component: Achievements
  },
  {
    pl: "/pl/korzysci",
    en: "/en/benefits",
    component: Benefits
  },
  {
    pl: "/pl/jak-pracujemy",
    en: "/en/how-we-work",
    component: HowWeWork
  },
  {
    pl: "/pl/blog",
    en: "/en/blog",
    exact: true,
    component: Blog
  },
  {
    pl: "/pl/blog/:slug",
    en: "/en/blog/:slug",
    component: Post
  },
  {
    pl: "/pl/portfolio",
    en: "/en/portfolio",
    exact: true,
    component: Portfolio
  },
  {
    pl: "/pl/portfolio/:slug",
    en: "/en/portfolio/:slug",
    component: Project
  },
  {
    pl: "/pl/warsztaty",
    en: "/en/workshops",
    component: Workshops
  },
  {
    pl: "/pl/kontakt",
    en: "/en/contact",
    component: Contact
  },
  {
    pl: "/pl/polityka-prywatnosci",
    en: "/en/privacy-policy",
    component: PrivacyPolicy
  },
  {
    pl: "/pl/landing",
    en: "/en/landing",
    exact: true,
    component: Landing
  },
  {
    pl: "/pl/",
    en: "/en/",
    exact: true,
    component: Home
  }
];

const Routing = () => {
  const lang = useSelector((state: ApplicationState) => state.lang.current);

  const location = useLocation();

  useEffect(() => {
    let key = "pl";
    if (lang === "pl") key = "en";

    const newRoute: NavigationRoute | undefined = routes.find(r => {
      return location.pathname.includes(r[key]);
    });

    if (newRoute) {
      const newPath = location.pathname.replace(newRoute[key], newRoute[lang]);
      window.location.replace(newPath);
    }
  }, [lang]);

  return (
    <Suspense fallback={<Spinner loading visible mode="full" />}>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to={`/${DEFAULT_LANG.shortcut}/`} />}
        />

        {routes.map((item, index) => {
          return (
            <Route
              key={item.pl}
              exact={item.exact}
              path={item.pl}
              component={item.component}
            />
          );
        })}
        {routes.map((item, index) => {
          return (
            <Route
              key={item.en}
              exact={item.exact}
              path={item.en}
              component={item.component}
            />
          );
        })}
        <Route exact component={Page404} />
      </Switch>
    </Suspense>
  );
};

export default Routing;
