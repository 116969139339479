import React from "react";
import clsx from "clsx";
import { __ } from "helpers/i18n";
import Header from "components/Common/Header";

interface Props {
  children: React.ReactNode;
  data: {
    header: string;
    description: string;
  };
  color: string;
}

const Card = (props: Props) => {
  const { children, data, color } = props;

  return (
    <div className={clsx("card", color)}>
      <div className="card__icon-border">
        <div className="card__icon-background">{children}</div>
      </div>
      <Header center type="h3">
        {data.header}
      </Header>
    </div>
  );
};

export default Card;
