import { SingleStory } from "./blog-reducer";

export interface EntryState {
  loading: boolean;
  content: SingleStory | null;
  next: SingleStory | null;
  prev: SingleStory | null;
}

const initialState = {
  loading: true,
  content: null,
  next: null,
  prev: null
};

interface loadingEntry {
  type: "LOADING_ENTRY";
  payload: boolean;
}
interface getEntry {
  type: "GET_ENTRY";
  payload: SingleStory;
}
interface getNext {
  type: "GET_ENTRY_NEXT";
  payload: SingleStory;
}
interface getPrev {
  type: "GET_ENTRY_PREV";
  payload: SingleStory;
}
interface clearEntry {
  type: "CLEAR_ENTRY";
}
type Action = getEntry | getNext | getPrev | loadingEntry | clearEntry;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case "LOADING_ENTRY":
      return { ...state, loading: action.payload };
    case "GET_ENTRY":
      return { ...state, content: action.payload };
    case "GET_ENTRY_PREV":
      return { ...state, prev: action.payload };
    case "GET_ENTRY_NEXT":
      return { ...state, next: action.payload };
    case "CLEAR_ENTRY":
      return initialState;
  }

  return state;
};
