import React from "react";
import { PortfolioItem } from "components/Common";
import { __, i18n } from "helpers/i18n";
import { SinglePortfolioItem } from "reducers/portfolio-reducer";

interface Props {
  items: SinglePortfolioItem[];
}

const PortflioItems = ({ items }: Props) => {
  return (
    <div className="portfolio-items">
      {items.length === 0 && <p className="coming-soon">{__("coming-soon")}</p>}
      {items.map((item, index) => (
        <PortfolioItem key={`photo-${index}`} item={item} />
      ))}
    </div>
  );
};

export default PortflioItems;
