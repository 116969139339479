import React, { Component } from "react";
import { Spinner } from "components/Common";
import "./Preloader.scss";

interface State {
  loading: boolean;
  visible: boolean;
}

export default class Preloader extends Component<{}, State> {
  constructor({}) {
    super({});
    this.state = {
      loading: true,
      visible: true
    };
  }

  componentDidMount() {
    setTimeout(
      () =>
        this.setState({
          loading: false
        }),
      500
    );
    // document.addEventListener(
    //   "DOMContentLoaded",
    //   () => {
    //     this.setState({
    //       loading: false
    //     });
    //   },
    //   false
    // );
  }

  render() {
    return (
      <div className="main-preloader">
        <Spinner
          mode="full"
          visible={this.state.visible}
          loading={this.state.loading}
        />
      </div>
    );
  }
}
