import React from "react";
import { __ } from "helpers/i18n";
import { SingleStory } from "reducers/blog-reducer";
import { format } from "date-fns";
import ReactMarkdown from "react-markdown";
import LocalizedLink from "components/Common/LocalizedLink";
import Button from "components/Common/Button";
import Header from "components/Common/Header";

export interface PostCardProps {
  data: SingleStory;
}

const PostCard = ({ data }: PostCardProps) => {
  return (
    <LocalizedLink to={`/blog/${data.slug}`} className={`post-card`}>
      <div
        className="post-card__background"
        style={{
          backgroundImage: `url(${data.content.image})`
        }}
      ></div>
      <div className="post-card__text-wrapper">
        <div className="post-card__header">
          <Header type="h3" center>
            {data.name}
          </Header>
        </div>

        <div className="post-card__info">
          <div className="post-card__info__date font-weight-bold">
            {format(new Date(data.published_at), "dd.MM.yyyy")}
          </div>

          <div className="post-card__info__category">
            {data.content.category.join(", ")}
          </div>
        </div>
        <p className="post-card__description paragraph-2 paragraph">
          <ReactMarkdown escapeHtml={false} source={data.content.excerpt} />
          <div className="post-card__description-overlay" />
        </p>
        <Button outline small text="more" to={`/blog/${data.slug}`} />
      </div>
    </LocalizedLink>
  );
};

export default PostCard;
