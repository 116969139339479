import React, { useEffect, useState } from "react";
import { scrollTop } from "utils/scroll-top";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const ScrollTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  // const handleScroll = (e: any) => {
  //   const scrollTop = e.target.documentElement.scrollTop;

  //   if (scrollTop < 1500) {
  //     setShowButton(false);
  //   } else {
  //     setShowButton(true);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  // }, []);

  return (
    <button
      onClick={scrollTop}
      className={`scroll-top-button ${
        !showButton ? " scroll-top-button--hidden" : ""
      }`}
    >
      <ExpandLessIcon style={{ color: "#fff", transform: "scale(1.3)" }} />
    </button>
  );
};

export default ScrollTopButton;
