import React, { useEffect, useRef } from "react";
import L from "leaflet";

interface Props {
  markerPosition: any;
  className?: String;
}

const LeafletMap = (props: Props) => {
  const { markerPosition, className } = props;
  const mapRef = useRef<any>(null);
  useEffect(() => {
    mapRef.current = L.map("map", {
      center: [markerPosition.lat, markerPosition.lng],
      zoom: 16,
      layers: [
        L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        }),
      ],
    });
  }, [markerPosition]);

  // add marker
  const markerRef = useRef<any>(null);
  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.setLatLng(markerPosition);
    } else {
      markerRef.current = L.marker(markerPosition).addTo(mapRef.current);
    }
    markerRef.current.bindPopup(
      '<a href="https://www.google.com/maps/dir/?api=1&destination=IT.focus,%20ul.%20Wolnego%204,%20Katowice" target="_blanc">Wskazówki dojazdu</a>'
    );
  }, [markerPosition]);

  return <div id="map" className={`leaflet-map ${className}`} />;
};

export default LeafletMap;
