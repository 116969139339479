import React from "react";

interface Props {
  children: any;
}

const SectionWrapper = ({ children }: Props) => {
  return <div className="section-wrapper">{children}</div>;
};

export default SectionWrapper;
