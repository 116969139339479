import { i18n } from "../helpers/i18n";
import SessionService from "../services/session-service";
import { act } from "react-dom/test-utils";

interface ChangeLanguage {
  type: "CHANGE_LANGUAGE";
  payload: string;
}

type Action = ChangeLanguage;

const initialState: any = {
  toggled: false,
  current: SessionService.getCurrentLanguage(),
  available: ["pl", "en"]
};

export default function(state = initialState, action: Action) {
  switch (action.type) {
    case "CHANGE_LANGUAGE": {
      i18n.changeLang(action.payload);
      return { ...state, current: action.payload };
    }
    default:
      return state;
  }
}
