import React, { useState } from "react";
import { Button, Spinner } from "components/Common";
import { __ } from "helpers/i18n";
import { ButtonsContainer } from "components/Layout";

interface Props {
  className?: String;
}

const mailMessages = [
  "Wiadomość została wysłana poprawnie.",
  "Pole imię/wiadomość nie może być puste.",
  "Musisz podać adres e-mail lub numer telefonu.",
  "Wystąpił błąd podczas wysyłania wiadomości, spróbuj ponownie później."
];

const ContactForm = (props: Props) => {
  const { className = "" } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [notification, setNotiification] = useState("");

  const handleSubmit = (e: any) => {
    // this.setState({ visible: true, loading: true });
    setLoading(true);
    setVisible(true);
    setError(false);
    setSuccess(false);
    setNotiification("");
    e.preventDefault();
    try {
      // fbq("track", "Lead");
      // ga("send", "event", "ContactForm", "send", "send");
    } catch (e) {}
    fetch("/sendmail.php", {
      method: "POST",
      body: JSON.stringify({
        email: email,
        name: name,
        phoneNo: phone,
        message: message
      })
    })
      .then(r => r.json())
      .then(response => {
        const code = response;
        // this.handleSpinner();
        if (code === 0) {
          setError(false);
          setSuccess(true);
          setNotiification(mailMessages[0]);
        } else {
          setError(true);
          setSuccess(false);
          setNotiification(mailMessages[code]);
        }
        handleSpinner();
      })
      .catch(error => {
        handleSpinner();
        setError(true);

        setSuccess(false);
        setNotiification(mailMessages[3]);
      });
  };

  const handleSpinner = () => {
    setLoading(false);

    setTimeout(() => {
      setVisible(false);
    }, 500);
  };

  return (
    <div className={`contact-form ${className}`}>
      <form className="contact-form__list" onSubmit={handleSubmit}>
        {success && (
          <div className="contact-form__message contact-form__success">
            {notification}
          </div>
        )}
        {error && (
          <div className="contact-form__message contact-form__error">
            {notification}
          </div>
        )}
        <Spinner mode="circle" visible={visible} loading={loading} />
        <input
          className="contact-form__list-field contact-form__list-field-name paragraph paragraph-2 background-color-white"
          placeholder={__("contact.form.name")}
          type="text"
          name="name"
          id="name"
          aria-label="formularz kontaktowy pole imię i nazwisko"
          value={name}
          required
          onChange={e => setName(e.target.value)}
        />
        <input
          className="contact-form__list-field contact-form__list-field-email paragraph paragraph-2"
          placeholder={__("contact.form.mail")}
          type="email"
          name="email"
          id="email"
          aria-label="formularz kontaktowy pole e-mail"
          value={email}
          required
          onChange={e => setEmail(e.target.value)}
        />
        <input
          className="contact-form__list-field contact-form__list-field-phone paragraph paragraph-2"
          placeholder={__("contact.form.phone")}
          type="phone"
          name="phone"
          id="phone"
          aria-label="formularz kontaktowy pole telefonu"
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />
        <textarea
          className="contact-form__list-field contact-form__list-field-message paragraph paragraph-2"
          placeholder={__("contact.form.message")}
          rows={8}
          name="message"
          id="message"
          aria-label="formularz kontaktowy pole wiadomości"
          value={message}
          onChange={e => setMessage(e.target.value)}
        ></textarea>
        <ButtonsContainer center className="contact-form-buttons">
          <Button primary type="submit" big text={"contact.form.btn"} />
        </ButtonsContainer>
      </form>
    </div>
  );
};

export default ContactForm;
