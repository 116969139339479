import React from "react";
import { animated, useSpring, config } from "react-spring";

const openedTransformationConfig = {
  top: "translate(2, 7) rotate(0)",
  center: "translate(2, 19) rotate(0)",
  bottom: "translate(2, 31) rotate(0)",
};

const closedTransformationConfig = {
  top: "translate(5, 32) rotate(-45)",
  center: "translate(10, 4) rotate(45)",
  bottom: "translate(5, 32) rotate(-45)",
};

const MenuIcon = (props: any) => {
  const { top, center, bottom } = useSpring({
    to: props.isOpened
      ? closedTransformationConfig
      : openedTransformationConfig,
    config: config.stiff,
  });
  return (
    <animated.svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
    >
      <animated.rect width="40" height="5" rx="0" transform={top} />
      <animated.rect width="40" height="5" rx="0" transform={center} />
      <animated.rect width="40" height="5" rx="0" transform={bottom} />
    </animated.svg>
  );
};

export default MenuIcon;
