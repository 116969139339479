import React, { useEffect, useState } from "react";
import {
  Button,
  DecorationDot,
  BottomLinks,
  Header,
  Spinner
} from "components/Common";
import { DecorationDotProps } from "components/Common/DecorationDot";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { scrollTop } from "utils/scroll-top";
import { Container } from "components/Layout";

interface Props {
  className?: String;
  header: String;
  headerContent?: React.ReactNode;
  children?: React.ReactNode;
  background?: string;
  link?: String;
  linkText?: string;
  margin?: boolean;
  nextPage?: { name: string; path: string };
  prevPage?: { name: string; path: string };
  loading?: boolean;
}

const SubpageWrapper = (props: Props) => {
  const [dots, setDots] = useState<DecorationDotProps[]>([]);
  const {
    className = "",
    children,
    header,
    link,
    linkText,
    margin,
    nextPage,
    prevPage,
    background,
    headerContent,
    loading
  } = props;

  useEffect(() => {
    clearAllBodyScrollLocks();
    scrollTop();
    generateDots();
  }, []);

  const generateDots = () => {
    const colors = ["accent-green", "accent-blue", "white"];
    const maxSize = 100;
    const minSize = 10;
    const count = Math.round(Math.random() * 5 + 2);

    let newDots = [];

    for (let i = 0; i < count; i++) {
      const size = Math.round(Math.random() * (maxSize - minSize) + minSize);
      newDots.push({
        color: colors[Math.round(Math.random() * 3)],
        style: {
          width: `${size}px`,
          height: `${size}px`,
          top: `${Math.round(Math.random() * 80 + 20)}%`,
          // opacity: "0.1",
          [Math.round(Math.random()) === 1 ? "right" : "left"]: `${-size / 2 +
            Math.round(Math.random() * 200)}px`
        }
      });
      setDots(newDots);
    }
  };

  return (
    <div
      className={`subpage-wrapper ${
        margin
          ? "subpage-wrapper__full-height"
          : "subpage-wrapper__normal-height"
      } ${className || ""}`}
    >
      <div className="subpage-wrapper__decoration-dots-group">
        {dots.map((item, index) => (
          <DecorationDot
            key={`decoration-dot-${index}`}
            className="subpage-wraapper__decoration-dot"
            color={item.color}
            style={item.style}
          />
        ))}
      </div>
      <div
        className={`subpage-wrapper__container ${!!background &&
          "with-background"} ${link ? "with-link" : ""}`}
        style={background ? { backgroundImage: `url(${background})` } : {}}
      >
        {background && <div className="subpage-wrapper__overlayer" />}
        <div className="subpage-wrapper__content">
          <Header noBottomMargin type="h1" center>
            {header}
          </Header>
          {headerContent}
          {link && linkText ? (
            <Button big to={`${link}`} text={linkText} />
          ) : (
            <div className="subpage-wrapper__space" />
          )}
        </div>
      </div>
      <div className={`subpage-wrapper__main ${className}`}>
        {loading && <Spinner mode="full" visible loading />}
        <Container>{children}</Container>
      </div>
      <BottomLinks nextPage={nextPage} prevPage={prevPage} />
      {/* <Background /> */}
    </div>
  );
};

export default SubpageWrapper;
