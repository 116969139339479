import React from "react";

import "./ButtonsContainer.scss";

interface Props {
  children: React.ReactNode;
  max?: boolean;
  center?: boolean;
  className?: string;
  baseline?: boolean;
  block?: boolean;
  end?: boolean;
}

const ButtonsContainer = ({
  max,
  center,
  children,
  className,
  baseline,
  block,
  end
}: Props) => (
  <div
    className={`buttons-container ${max ? "max" : ""} ${block ? "block" : ""} ${
      end ? "end" : ""
    }${className || ""} ${center ? "center" : ""} ${
      baseline ? "baseline" : ""
    }`}
  >
    {children}
  </div>
);

export default ButtonsContainer;
