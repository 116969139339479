import React, { Component } from "react";

interface Props {
  visible: boolean;
  loading: boolean;
  mode: string;
}

interface State {
  preloaderClass: string;
}

class Spinner extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    if (this.props.visible) {
      this.state = {
        preloaderClass: "preloader loading"
      };
    } else {
      this.state = {
        preloaderClass: "preloader hidden"
      };
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    this.handleVisibility(nextProps);
    this.handleLoading(nextProps);
  }

  handleVisibility = (nextProps: Props) => {
    if (
      nextProps.visible === true &&
      this.props.visible !== nextProps.visible
    ) {
      this.setState({ preloaderClass: "preloader loading" });
    } else if (nextProps.visible === false) {
      this.setState({ preloaderClass: "preloader hidden" });
    }
  };

  handleLoading = (nextProps: Props) => {
    if (
      nextProps.loading === false &&
      this.props.loading !== nextProps.loading
    ) {
      if (this.props.mode === "full") {
        this.setReady();
      } else {
        this.setState({ preloaderClass: "preloader loading fade" });
      }
    }
  };

  setReady = () => {
    this.setState(
      {
        preloaderClass: "preloader ready"
      },
      () => {
        this.setLoaded();
      }
    );
  };

  setLoaded = () => {
    setTimeout(() => {
      this.setState(
        {
          preloaderClass: "preloader loaded"
        },
        () => {
          this.setFade();
        }
      );
    }, 700);
  };

  setFade = () => {
    setTimeout(() => {
      this.setState(
        {
          preloaderClass: "preloader fade"
        },
        () => {
          this.setHidden();
        }
      );
    }, 700);
  };

  setHidden = () => {
    setTimeout(() => {
      this.setState({ preloaderClass: "preloader hidden" });
    }, 200);
  };

  render() {
    return (
      <div className={`${this.state.preloaderClass} ${this.props.mode}`}>
        <div className="logo-container">
          <div className="logo">
            <div className="circle circle-1"></div>
            <div className="connector connector-vertical connector-1"></div>
            <div className="connector connector-vertical connector-2"></div>
            <div className="connector connector-vertical connector-3"></div>
            <div className="connector connector-horizontal connector-4"></div>
            <div className="circle circle-2"></div>
            <div className="circle circle-3"></div>
            <div className="circle circle-4"></div>
            <div className="circle circle-5"></div>
            <div className="circle circle-6"></div>
            <div className="circle circle-7"></div>
            <div className="circle circle-8"></div>
            <div className="circle circle-9"></div>
          </div>
          <img
            src="/assets/itfocus.svg"
            className="title"
            alt="logo it dot focus"
          />
        </div>
      </div>
    );
  }
}

export default Spinner;
