const LANGUAGES = [
  {
    name: "English",
    shortcut: "en"
  },
  {
    name: "Polski",
    shortcut: "pl"
  }
];

const DEFAULT_LANG = {
  name: "DEFAULT_LANG",
  shortcut: "pl"
};

export { LANGUAGES, DEFAULT_LANG };
