import React, { useEffect } from "react";

interface Props {
  children: React.ReactNode;
}

const Main = (props: Props) => {
  const { children } = props;

  useEffect(() => {}, []);

  return <main className="main"> {children} </main>;
};

export default Main;
