import React, { useEffect } from "react";
import { __ } from "helpers/i18n";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { BigCircleTab, Header, Button } from "components/Common";
import DecorationDot, {
  DecorationDotProps
} from "components/Common/DecorationDot";
import Section from "components/Layout/Section";
import { ButtonsContainer } from "components/Layout";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "reducers";
import { getPortfolioEntries } from "actions";

const dotsArr: Array<DecorationDotProps> = [
  {
    color: "accent-blue",
    style: { width: "1vw", height: "1vw", top: "17vw", left: "10vw" }
  },
  {
    color: "accent-green",
    style: { width: "2.5vw", height: "2.5vw", top: "20vw", left: "6vw" }
  }
];

const LandingPortfolio = () => {
  const language = useSelector((state: ApplicationState) => state.lang.current);
  const dispatch = useDispatch();

  const { entries, loading } = useSelector(
    (state: ApplicationState) => state.portfolio
  );

  useEffect(() => {
    dispatch(getPortfolioEntries(1, language));
  }, []);

  return (
    <Section light>
      <div className="landing-portfolio__decoration-dots-group">
        {dotsArr.map((item, index) => (
          <DecorationDot
            key={`decoration-dot-${index}`}
            color={item.color}
            style={item.style}
          />
        ))}
      </div>

      <Header type="h2" center light>
        {__("our_portfolio_1")} <strong>{__("our_portfolio_2")}</strong>
      </Header>
      <p className="landing-portfolio__soon">{__("portfolio.description")}</p>
      <div className="landing-portfolio__case-study">
        {/* <p>{__("portfolio.description")}</p>
        <LinkButton
          link="/portfolio/browar-jastrzebie"
          size="big"
          header="Case study"
        /> */}
      </div>
      {/* <p className="landing-portfolio__paragraph paragraph-2 paragraph paragraph-color-grey-dark">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a lorem et
        lacus
      </p> */}
      <div className="landing-portfolio__list">
        {entries.slice(0, 3).map((entry, index) => (
          <BigCircleTab
            link={`/portfolio/${entry.slug}`}
            header={entry.content.ShortTitle}
            paragraph={entry.name}
            img={
              entry.content.Images[0]
                ? entry.content.Images[0].Images[0].filename
                : null
            }
            className="landing-portfolio__list-item"
            background={index === 0}
          />
        ))}
      </div>
      <ButtonsContainer center>
        <Button
          big
          primary
          text="see_more"
          icon={faArrowRight}
          to="/portfolio"
        />
      </ButtonsContainer>
    </Section>
  );
};

export default LandingPortfolio;
