import React from "react";
import Logo from "assets/logo_IT_focus.svg";
import Arrow from "assets/arrow.png";
import {
  IconSpeechBubble,
  IconCode,
  IconPicture,
} from "components/Common/SVG-icons";
import { __ } from "helpers/i18n";

interface Props {
  className?: String;
}

const CircleWorkSchema = (props: Props) => {
  const { className } = props;

  return (
    <div className={`circle-work-schema ${className || ""}`}>
      <div className="circle-work-schema__wrapper circle-work-schema__wrapper-top">
        <IconCode className="circle-work-schema__wrapper-img" />
        <div className="circle-work-schema__wrapper-text">
          {__("how-we-work.work")}
        </div>
      </div>
      <div className="circle-work-schema__wrapper circle-work-schema__wrapper-left">
        <IconSpeechBubble className="circle-work-schema__wrapper-img" />
        <div className="circle-work-schema__wrapper-text">
          {__("how-we-work.conversation")}
        </div>
      </div>
      <img
        src={Arrow}
        alt=""
        className="circle-work-schema__arrow circle-work-schema__arrow-top"
      />
      <div className="circle-work-schema__border">
        <div className="circle-work-schema__border-dot circle-work-schema__border-dot-left" />
        <div className="circle-work-schema__border-dot circle-work-schema__border-dot-top" />
        <div className="circle-work-schema__border-dot circle-work-schema__border-dot-right" />
        <div className="circle-work-schema__border-dot circle-work-schema__border-dot-bottom" />
        <div className="circle-work-schema__background">
          <img src={Logo} alt="" className="circle-work-schema__logo" />
        </div>
      </div>
      <img
        src={Arrow}
        alt=""
        className="circle-work-schema__arrow circle-work-schema__arrow-bottom"
      />
      <div className="circle-work-schema__wrapper circle-work-schema__wrapper-right">
        <IconPicture className="circle-work-schema__wrapper-img" />
        <div className="circle-work-schema__wrapper-text">
          {__("how-we-work.presentation")}
        </div>
      </div>
    </div>
  );
};

export default CircleWorkSchema;
