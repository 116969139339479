export interface PortfolioState {
  loading: boolean;
  entries: SinglePortfolioItem[];
  total: number;
}

const initialState = {
  loading: true,
  entries: [],
  lastEntry: null,
  total: 0
};

export interface PortfolioContent {
  content: {
    type: string;
    content: {
      type: string;
      text: string;
    }[];
  }[];
}

export interface PortfolioImage {
  alt: string | null;
  name: string;
  title: string | null;
  id: number;
  filename: string;
}

export interface SinglePortfolioItem {
  content: {
    CaseStudyPdfUrl: {
      id: string;
      url: string;
    };
    Excerpt: string;
    Description?: string;
    Effects?: string;
    Images: {
      Images: PortfolioImage[];
    }[];
    LiveUrl: {
      url: string;
    };
    OtherInformation?: string;
    SolutionsAndScope: string[];
    content: string;
    excerpt: string;
    title: string;
    ShortTitle: string;
    Client?: string;
    image: string;
  };
  position: number;
  created_at: Date | string;
  published_at: Date | string;
  id: number;
  name: string;
  slug: string;
  translated_slugs: {
    lang: string;
    name: string;
    path: string;
  }[];
}

interface loadingPortfolioEntries {
  type: "LOADING_PORTFOLIO_ENTRIES";
  payload: boolean;
}
interface getPortfolioEntries {
  type: "GET_PORTFOLIO_ENTRIES";
  payload: SinglePortfolioItem[];
}

interface getPortflioTotal {
  type: "GET_PORTFOLIO_TOTAL";
  payload: number;
}

type Action = loadingPortfolioEntries | getPortfolioEntries | getPortflioTotal;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case "LOADING_PORTFOLIO_ENTRIES":
      return { ...state, loading: action.payload };

    // case "GET_BLOG_TOTAL":
    //   return { ...state, total: action.payload };
    case "GET_PORTFOLIO_ENTRIES":
      return { ...state, entries: action.payload };
  }

  return state;
};
