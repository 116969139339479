import React from "react";
import { __ } from "helpers/i18n";
import Header from "components/Common/Header";

interface Props {
  header: string;
}

const ButtonBorder = (props: Props) => {
  const { header } = props;
  return (
    <div className={`button-border`}>
      <Header
        noBottomMargin
        type="h4"
        text={header}
        className="button-border__header"
      />
      <div className="button-border__background" />
    </div>
  );
};

export default ButtonBorder;
