import React from "react";

interface Props {
  children: React.ReactNode;
  center?: boolean;
}

const Container = ({ children, center }: Props) => (
  <div className={`container ${center ? "center" : ""}`}>{children}</div>
);

export default Container;
