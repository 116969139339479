import React from "react";
import SearchIco from "assets/search-ico.png";

interface Props {
  className?: String;
}

const SearchBox = (props: Props) => {
  const { className = "" } = props;

  return (
    <div className={`search-box ${className}`}>
      <input
        className="search-box__input"
        placeholder="przeszukaj blog"
        type="text"
        name=""
        id=""
        aria-label="pole wyszukiwarki"
      />
      <button className="search-box__button" aria-label="wyszukaj">
        <img className="search-box__button-ico" src={SearchIco} alt="" />
      </button>
    </div>
  );
};

export default SearchBox;
