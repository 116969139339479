import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LocalizedLink from "components/Common/LocalizedLink";
import { __ } from "helpers/i18n";

interface ButtonProps {
  text?: string;
  to?: string;
  click?: any;
  primary?: boolean;
  secondary?: boolean;
  danger?: boolean;
  success?: boolean;
  type?: string;
  big?: boolean;
  small?: boolean;
  disabled?: boolean;
  blank?: boolean;
  outline?: boolean;
  icon?: any;
}

enum ButtonType {
  Submit = "submit",
  Button = "button",
  Reset = "reset"
}

const Button = (props: ButtonProps): any => {
  const [ripples, setRipples] = useState<{ x: number; y: number }[]>([]);

  const {
    primary,
    small,
    big,
    disabled,
    blank,
    to,
    type,
    click,
    text,
    secondary,
    outline,
    icon,
    danger,
    success
  } = props;

  const setButtonType = (userinput = "button"): any => {
    switch (userinput) {
      case ButtonType.Button:
        return "button";
      case ButtonType.Submit:
        return "submit";
      default:
        return "button";
    }
  };

  const buttonClass = `button
 ${primary ? "button-primary" : ""} 
    ${secondary ? "button-secondary" : ""} 
    ${big ? "button-big" : ""} 
    ${small ? "button-small" : ""}
  
    ${disabled ? "button-disabled" : ""}
    ${outline ? "button-outline" : ""}
    ${danger ? "button-danger" : ""}
    ${success ? "button-success" : ""}
    ${!text ? "button-no-text" : ""}
    `;
  const content = (
    <>
      <span className="button-text">{__(text)}</span>
      {icon && <FontAwesomeIcon className="button-icon" icon={icon} />}
    </>
  );

  if (to) {
    if (disabled) {
      return <a className={buttonClass}>{content}</a>;
    }
    if (blank) {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={buttonClass}
          href={to}
          onClick={click}
        >
          {content}
        </a>
      );
    }
    return (
      <LocalizedLink className={buttonClass} to={to} onClick={click}>
        {content}
      </LocalizedLink>
    );
  }

  return (
    <button
      type={setButtonType(type) || "button"}
      disabled={disabled}
      onClick={click}
      className={buttonClass}
    >
      {content}
    </button>
  );
};

export default Button;
