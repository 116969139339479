import React, { useState } from "react";
import { __ } from "helpers/i18n";
import { Card, Header, Paragraph, List } from "components/Common";
import {
  IconGraphicDesign,
  IconUi,
  IconBuy
} from "components/Common/SVG-icons";
import Section from "components/Layout/Section";

const LandingMain = () => {
  const [card] = useState([
    {
      icon: <IconGraphicDesign fill="#fff" width="40%" />,
      color: "dark-blue-darker",
      data: {
        header: __("landing.card.0.header"),
        description: __("landing.card.0.description")
      }
    },
    {
      icon: <IconUi fill="#fff" width="40%" />,
      color: "accent-green-light",
      data: {
        header: __("landing.card.1.header"),
        description: __("landing.card.1.description")
      }
    },
    {
      icon: <IconBuy fill="#fff" width="40%" />,
      color: "accent-blue",
      data: {
        header: __("landing.card.2.header"),
        description: __("landing.card.2.description")
      }
    }
  ]);

  return (
    <Section dark>
      <Header>{__("landing.sections.we-create.header")}</Header>

      <List>
        <li>
          <p>
            <strong>
              {__("landing.sections.we-create.paragraph.0.title")}
            </strong>
            {__("landing.sections.we-create.paragraph.0.content")}
          </p>
        </li>
        <li>
          <p>
            <strong>
              {__("landing.sections.we-create.paragraph.1.title")}
            </strong>
            {__("landing.sections.we-create.paragraph.1.content")}
          </p>
        </li>
        <li>
          <p>
            <strong>
              {__("landing.sections.we-create.paragraph.2.title")}
            </strong>
            {__("landing.sections.we-create.paragraph.2.content")}
          </p>
        </li>
      </List>
      <div className="landing-we-create__card-group">
        {card.map((item, index) => (
          <Card key={`card-${index}`} color={item.color} data={item.data}>
            {item.icon}
          </Card>
        ))}
      </div>
    </Section>
  );
};

export default LandingMain;
