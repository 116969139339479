import React from "react";
import { Button, Header } from "components/Common";
import background from "assets/background-landing-blog.png";
import Section from "components/Layout/Section";
import { __, i18n } from "helpers/i18n";

const LandingBlog = () => (
  <Section dark className="landing-blog">
    <div className="landing-blog__background-wrapper">
      <img src={background} alt="" className="landing-blog__background" />
    </div>
    <div className="landing-blog__content-wrapper">
      <div className="landing-blog__header-group">
        <Header type="h2" light big>
          {__("landing.sections.blog.header.0")}{" "}
          <strong>{__("landing.sections.blog.header.1")}</strong>
        </Header>
      </div>
      <Button
        big
        primary
        text={__("landing.sections.blog.btn")}
        to={`/${__("paths.blog")}`}
      />
    </div>
  </Section>
);

export default LandingBlog;
