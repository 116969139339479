import React from "react";

interface Props {
  to: string;
  className?: String;
  hover?: String;
  Icon: any;
  svgFill?: String;
  bgColor?: String;
  ariaLabel?: string;
}

const CircleButton = (props: Props) => {
  const {
    hover = "darker",
    className = "",
    Icon,
    svgFill = "white",
    bgColor = "dark-blue",
    to,
    ariaLabel,
  } = props;

  return (
    <a
      href={to}
      target="__blank"
      className={`circle-button  circle-button-color-${svgFill} background-color-${bgColor} ${className}`}
      aria-label={ariaLabel}
    >
      <div
        className={`circle-button__overlay circle-button__overlay-${hover}`}
      ></div>
      <Icon className={`circle-button__ico`} />
    </a>
  );
};

export default CircleButton;
