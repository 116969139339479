import React from "react";
import { CircleButton } from "components/Common";

import {
  IconFacebookBg,
  IconLinkedin,
  IconInstagram,
} from "components/Common/SVG-icons";

interface Props {
  className?: String;
}

const SocialBar = (props: Props) => {
  const { className } = props;

  return (
    <section className={`social-bar ${className || ""}`}>
      <div className="social-bar__list">
        <CircleButton
          Icon={IconFacebookBg}
          hover="lighter"
          to="https://www.facebook.com/itdotfocus"
          ariaLabel="odnośnik do facebook"
        />
        <CircleButton
          Icon={IconInstagram}
          hover="darker"
          bgColor="accent-blue"
          to="https://www.instagram.com/itdotfocus/"
          ariaLabel="odnośnik do instagram"
        />
        <CircleButton
          Icon={IconLinkedin}
          hover="lighter"
          to="https://www.linkedin.com/company/14870808/"
          ariaLabel="odnośnik do linkedin"
        />
        {/* <CircleButton Icon={IconGooglePlus} hover="lighter" /> */}
      </div>
    </section>
  );
};

export default SocialBar;
