import React from "react";
import { Link } from "react-router-dom";
import { __, i18n } from "helpers/i18n";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Container } from "components/Layout";
import LocalizedLink from "components/Common/LocalizedLink";

interface Props {
  nextPage?: { name: string; path: string };
  prevPage?: { name: string; path: string };
}

const BottomLinks = (props: Props) => {
  const { nextPage, prevPage } = props;

  return nextPage || prevPage ? (
    <Container>
      <div className="bottom-links__links ">
        {nextPage && (
          <LocalizedLink
            to={nextPage.path}
            className="bottom-links__btn bottom-links__next-btn"
          >
            <ArrowBackIosIcon />
            <span>{`${__(`${nextPage.name}`)}`}</span>
          </LocalizedLink>
        )}
        {prevPage && (
          <LocalizedLink
            to={prevPage.path}
            className="bottom-links__btn bottom-links__prev-btn"
          >
            <span>{`${__(`${prevPage.name}`)}`}</span>
            <ArrowForwardIosIcon />
          </LocalizedLink>
        )}
      </div>
    </Container>
  ) : null;
};

export default BottomLinks;
