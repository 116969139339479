import React from "react";

interface Props {
  children: any;
}

function List({ children }: Props) {
  return <ul className="list">{children}</ul>;
}

export default List;
