import React from "react";
import { SinglePortfolioItem } from "reducers/portfolio-reducer";
import Header from "components/Common/Header";
import { Button } from "components/Common";
import { ButtonsContainer } from "components/Layout";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export interface GalleryPhotoProps {
  item: SinglePortfolioItem;
}

const GalleryPhoto = ({ item }: GalleryPhotoProps) => {
  return (
    <div className="portfolio-item">
      {item.content.Images[0] && (
        <div className="portfolio-item-left">
          <img
            className="portfolio-item-image"
            src={item.content.Images[0].Images[0].filename}
            alt=""
          />
        </div>
      )}
      <div className="portfolio-item-right">
        <div>
          <p className="portfolio-item-short">{item.content.ShortTitle}</p>
          <Header className="portfolio-item-header" type="h3">
            {item.name}
          </Header>
        </div>
        <p>{item.content.Excerpt}</p>
        <ButtonsContainer end>
          <Button
            primary
            text="read_more"
            to={`/portfolio/${item.slug}`}
            icon={faArrowRight}
          />
        </ButtonsContainer>
      </div>
      {/* <LocalizedLink to={link} className={`gallery-photo ${className}`}> */}
      {/* <div className='portfolio-item-' */}
      {/* <div className="gallery-photo__wrapper"></div>
      <div className="gallery-photo__overlay">
        <h3 className="gallery-photo__header header header-3 font-weight-bold font-color-white">
        
        </h3>
        <div className="gallery-photo__paragraph paragraph paragraph-2 font-color-white">
          {paragraph}
        </div>
      </div> */}
      {/* </LocalizedLink> */}
    </div>
  );
};

export default GalleryPhoto;
