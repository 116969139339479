import React from "react";
import { __ } from "helpers/i18n";
import clsx from "clsx";

interface Props {
  big?: boolean;
  center?: boolean;
  text?: string;
  children?: React.ReactNode;
}

const Paragraph = ({ big, text, center, children }: Props) => {
  return (
    <p className={clsx("paragraph", { big, center })}>
      {__(text)}
      {children}
    </p>
  );
};

export default Paragraph;
