import React from "react";
import { __ } from "helpers/i18n";
import { Carousel, Header } from "components/Common";
import Section from "components/Layout/Section";
import browar_jastrzebie from "../../../assets/browar_jastrzebie.png";
import digital_kid from "../../../assets/digital_kid.png";
import spin_digital from "../../../assets/spin_digital.png";

const LandingFeedback = () => {
  return (
    <Section>
      <Header center>
        {__("landing.sections.feedback.header.0")}
        {__("landing.sections.feedback.header.1")}
      </Header>
      <Carousel
        className="landing-feedback__carousel"
        data={[
          {
            header: "",
            description:
              "Firma IT.focus od początku uczestniczy w dzianiach Browaru Jastrzębie S.A. Już na etapie rekordowej w Polsce zbiórce crowdfundingowej, IT.focus dostarczyło narzędzia które pomogły napędzić akcję. Polecamy firmę IT.focus, ponieważ nie tylko wykoonuje powierzone zadania, ale kreatywnie podchodzi do problemów, doradza możliwe rozwiązania w sposób skuteczny i optymalny kosztowo.",
            author: "Piotr Piekarski, Prezes Browar Jastrzębie S.A.",
            photo: browar_jastrzebie
          },
          {
            header: "",
            description:
              "Od kilku miesięcy realizujemy projekt aplikacji webowej PWA z IT.focus. Aplikacja okazała się spełniać cłkowicie swoje zadanie i przyniosła odpowiedni poziom zwrotu z inwestycji. IT.focus jest partnerem doświadczonym i uczciwym. Rekomendujemy współpracę z nimi.",
            author: "Mariusz Pieniak, CEO Digital Kid",
            photo: digital_kid
          },
          {
            header: "",
            description:
              "Zrealizowaliśmy z IT.focus prjekt platformy streamingowej. Od samego początku IT.focus wykazywało się dużym zaangażowaniem oraz wzorową współpracą. Dzięki temu stworzona platforma spełnia swoje założenia, a także odpowiednio daje możliwości marketingowe i promocyjne, które na co dzień wykorzystujemy.",
            author: "Filip Kasprzyk, COO Spin Digital",
            photo: spin_digital
          }
        ]}
      />
    </Section>
  );
};

export default LandingFeedback;
