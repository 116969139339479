import React from "react";
import {
  IconFacebook,
  IconPinterest,
  IconEnvelope,
  IconTwitter
} from "components/Common/SVG-icons";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  PinterestShareButton
} from "react-share";
import { useLocation } from "react-router-dom";

interface Props {
  date: string;
  categories: string[];
}

const PostBar = (props: Props) => {
  let location = useLocation();
  const { date, categories } = props;
  const shareUrl = `https://itdotfocus.com/${location.pathname}`;

  return (
    <div className={`post-bar`}>
      <div className="post-bar__info">
        <div className="post-bar__info-date">{date}</div>
        <div className="post-bar__info-categories">
          {categories.map(c => (
            <div className="post-bar__info-category">{c}</div>
          ))}
        </div>
      </div>
      {/* <div className="post-bar__social">
        <div className="post-bar__social-item">
          <FacebookShareButton url={shareUrl}>
            <IconFacebook className="post-bar__social-svg" />
          </FacebookShareButton>
        </div>
        <div className="post-bar__social-item">
          <TwitterShareButton url={shareUrl}>
            <IconTwitter className="post-bar__social-svg" />
          </TwitterShareButton>
        </div>
        <div className="post-bar__social-item">
          <EmailShareButton url={shareUrl}>
            <IconEnvelope className="post-bar__social-svg" />
          </EmailShareButton>
        </div>
        <div className="post-bar__social-item">
          <PinterestShareButton url={shareUrl} media={img}>
            <IconPinterest className="post-bar__social-svg" />
          </PinterestShareButton>
        </div>
        <div className="post-bar__social-item">+</div>
      </div> */}
    </div>
  );
};

export default PostBar;
