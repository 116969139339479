import React from "react";
import { __ } from "helpers/i18n";
import clsx from "clsx";

interface Props {
  text?: string;
  children?: React.ReactNode;
  type?: "h1" | "h2" | "h3" | "h4";
  center?: boolean;
  light?: boolean;
  big?: boolean;
  className?: string;
  noBottomMargin?: boolean;
}

const Header = ({
  children,
  text,
  type,
  className,
  center,
  noBottomMargin,
  light,
  big
}: Props) => {
  const headerType = type || "h2";
  const classes = clsx("header", className, `header-${headerType}`, {
    center,
    light,
    "no-bottom-margin": noBottomMargin,
    big
  });
  const content = (
    <>
      {__(text)}
      {children}
    </>
  );
  return (
    <>
      {headerType === "h1" && <h1 className={classes}>{content}</h1>}
      {headerType === "h2" && <h2 className={classes}>{content}</h2>}
      {headerType === "h3" && <h3 className={classes}>{content}</h3>}
      {headerType === "h4" && <h4 className={classes}>{content}</h4>}
    </>
  );
};

export default Header;
