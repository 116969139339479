import React from "react";
import { Link } from "react-router-dom";
import { i18n } from "../../../helpers/i18n";

interface Props {
  to: string;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const LocalizedLink = ({ className, to, children, onClick }: Props) => (
  <Link
    to={`/${i18n.currentLang}${to}`}
    className={className}
    onClick={onClick}
  >
    {children}
  </Link>
);

export default LocalizedLink;
