import React, { Component } from "react";

interface Props {
  href: string;
  children: React.ReactNode;
}

function LinkToYTTransformer({ href, children }: Props) {
  let ytId = "";

  const regex = /youtube.com(\/watch?.*?v=(.*)|\/embed\/(.*))/gm;
  let m;

  while ((m = regex.exec(href)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    if (m[2]) {
      ytId = m[2];
    } else if (m[3]) {
      ytId = m[3];
    }
  }

  if (ytId) {
    return (
      <div className="youtube-embed">
        <iframe
          src={`https://www.youtube-nocookie.com/embed/${ytId}`}
          frameBorder={0}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    );
  } else {
    return (
      <a href={href} target="_blank">
        {children}
      </a>
    );
  }
}

export default LinkToYTTransformer;
