import React from "react";

import { __ } from "helpers/i18n";
import { Header } from "components/Common";

interface Props {
  header: string;
  Icon: any;
  align?: string;
  color: string;
}

const CircleBanner = (props: Props) => {
  const { header, Icon, align = "left", color = "dark-blue" } = props;
  return (
    <div className={`circle-banner circle-banner-align-${align} ${color}`}>
      <div
        className={`circle-banner__background circle-banner-align-${align}__background`}
      >
        <div className={`circle-banner__ico circle-banner-align-${align}__ico`}>
          <Icon />
        </div>
      </div>
      <div
        className={`circle-banner__text-group circle-banner-align-${align}__text-group`}
      >
        <Header text={header} />
      </div>
    </div>
  );
};

export default CircleBanner;
