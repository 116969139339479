import React from "react";
import { Header, Paragraph } from "components/Common";

interface Props {
  icon: string;
  header?: string;
  line1?: string;
  line2?: string;
  link?: {
    title: string;
    to: string;
  };
}

function ContactInfo({ icon, header, line1, line2, link }: Props) {
  return (
    <div className="contact-info__wrapper">
      <img className="contact-info__ico" src={icon} alt="" />
      <div className="contact-info__text-wrapper">
        {header && <Header type="h3" text={header} />}
        {line1 && <Paragraph text={line1} />}
        {line2 && <Paragraph text={line2} />}
        {link && <a href={link.to}>{link.title}</a>}
      </div>
    </div>
  );
}

export default ContactInfo;
