import React, { useState } from "react";
import { Button } from "components/Common";
import Modal from "react-modal";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

interface Props {
  modalIsOpen: boolean;
  closeModal: any;
}

const NewsletterForm = (props: Props) => {
  const { modalIsOpen, closeModal } = props;

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Newsletter Modal"
        className="newsletter__modal"
        overlayClassName="newsletter__overlay"
        // style={customModalStyles}
      >
        <div className={`newsletter section-wrapper`}>
          <div className="newsletter__text-wrapper">
            <h2 className="newsletter__header header-2 header font-color-dark-blue">
              <span className="font-weight-regular">{`Otrzymuj nasze `}</span>
              <span className="font-weight-bold">artykuły!</span>
            </h2>
            <p className="newsletter__paragraph paragraph-1 paragraph font-color-accent-green">
              Wpisz się na naszą listę, żeby otrzymywać artykuły z naszego bloga
              na swój e-mail.
            </p>
            <form className="newsletter__form">
              <label
                className="newsletter__label font-color-dark-blue paragraph-1"
                htmlFor="name"
              >
                IMIĘ
              </label>
              <input
                className="newsletter__form-input"
                type="text"
                name=""
                id="name"
              />
              <label
                className="newsletter__label font-color-dark-blue paragraph-1"
                htmlFor="email"
              >
                E-MAIl
              </label>
              <input
                className="newsletter__form-input"
                type="text"
                name=""
                id="email"
              />
              <Button big text="Zapisz się" />
              />
            </form>
            <p className="newsletter__paragraph paragraph-1 paragraph font-color-dark-blue font-weight-bold">
              Tylko i wyłącznie artykuły z naszego bloga. Też nie lubimy spamu
              :)
            </p>
          </div>
        </div>
        <div className="newsletter-modal__close-btn" onClick={closeModal}>
          <HighlightOffIcon />
        </div>
      </Modal>
    </>
  );
};

export default NewsletterForm;
