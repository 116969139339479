import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { LangButton, MenuIcon, Button } from "components/Common";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { __, i18n } from "helpers/i18n";

import Logo from "assets/logo_IT_focus.svg";
import LocalizedLink from "components/Common/LocalizedLink";
import Container from "components/Layout/Container";
import { ButtonsContainer } from "components/Layout";

const Navbar = (props: any) => {
  const [active, setActive] = useState(false);
  const [list] = useState([
    { link: `/${__("paths.home")}`, key: "home" },
    { link: `/${__("paths.offer")}`, key: "offer" },
    { link: `/${__("paths.benefits")}`, key: "benefits" },
    { link: `/${__("paths.how-we-work")}`, key: "how-we-work" },
    { link: `/${__("paths.achievements")}`, key: "achievements" },
    { link: `/${__("paths.portfolio")}`, key: "portfolio" },
    { link: `/${__("paths.contact")}`, key: "contact" },
    { link: `/${__("paths.workshops")}`, key: "workshops" }
  ]);

  const collapseNavigation = (val: boolean = false) => {
    if (active || val) {
      enableBodyScroll(document.body);
      setActive(false);
    } else {
      disableBodyScroll(document.body);
      setActive(true);
    }
  };

  return (
    <div className="navbar-wrapper">
      <Container>
        <nav className="navbar">
          <LocalizedLink to="/" className="navbar__logo">
            <img alt="logo IT.focus" className="navbar__logo-img" src={Logo} />
          </LocalizedLink>
          <div
            className={`navbar__navigation ${
              active
                ? "navbar__navigation--active"
                : "navbar__navigation--hidden"
            }`}
          >
            <div className="navbar__navigation__wrapper">
              <ul className="navbar__navigation-list">
                {list.map((item, index) => (
                  <li className="navbar__navigation-list__item">
                    <LocalizedLink
                      key={`item-${index}`}
                      to={`/${__("paths." + item.key)}`}
                      className={`navbar__navigation-list__item-link ${
                        props.location.pathname.includes(item.link)
                          ? "navbar__navigation-list__item-link--active"
                          : ""
                      }`}
                      onClick={() => collapseNavigation(true)}
                    >
                      {__(`navbar.${item.key}`)}
                    </LocalizedLink>
                  </li>
                ))}
              </ul>
              <ButtonsContainer className="navbar-buttons">
                <Button
                  primary
                  small
                  click={() => collapseNavigation(true)}
                  text="blog"
                  to={`/${__("paths.blog")}`}
                />

                <LangButton />
              </ButtonsContainer>
            </div>
          </div>

          <div className="navbar__hamburger-wrapper">
            <div
              className="navbar__hamburger-icon"
              onClick={e => collapseNavigation()}
            >
              <MenuIcon isOpened={active} />
            </div>
          </div>
        </nav>
      </Container>
    </div>
  );
};

export default withRouter(Navbar);
