import React from "react";
import { __ } from "helpers/i18n";
import { Header, Paragraph } from "components/Common";
import Section from "components/Layout/Section";

const LandingMain = () => {
  return (
    <Section className="landing-main">
      <div className="landing-main__text-wrapper">
        <Header
          text="landing.sections.main.header"
          big
          className="main-header"
        />

        <Paragraph text="landing.sections.main.paragraph.0" />
        <Paragraph text="landing.sections.main.paragraph.1" />
        <Paragraph text="landing.sections.main.paragraph.2" />
        <Paragraph text="landing.sections.main.paragraph.3" />
        <Paragraph text="landing.sections.main.paragraph.4" />
      </div>
    </Section>
  );
};

export default LandingMain;
