import React from "react";
import { SocialBar, ContactForm, Header, Paragraph } from "components/Common";
import { __ } from "helpers/i18n";
import Section from "components/Layout/Section";

const LandingContact = () => (
  <Section>
    <Header>
      {__("landing.sections.contact.header.0")}
      {__("landing.sections.contact.header.1")}
    </Header>

    <Paragraph text="landing.sections.contact.paragraph.0" />
    <Paragraph text="landing.sections.contact.paragraph.1" />
    <Paragraph text="landing.sections.contact.paragraph.2" />

    <div className="landing-contact__wrapper">
      <ContactForm className="landing-contact__form" />
    </div>
    <SocialBar className="landing-contact__social-bar" />
  </Section>
);

export default LandingContact;
