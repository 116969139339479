import React from "react";
import { DecorationDot, Header, Paragraph, List } from "components/Common";
import { __ } from "helpers/i18n";
import { Container } from "components/Layout";
import Section from "components/Layout/Section";

const LandingAboutUs = () => (
  <Section>
    <DecorationDot
      color="accent-green"
      style={{ width: "8vw", height: "8vw", top: "-4vw", right: "-4vw" }}
    />

    <Header text="landing.sections.about-us.header" />

    <Paragraph text="landing.sections.about-us.paragraph" />

    <List>
      <li>
        <Paragraph text="landing.sections.about-us.list.0" />
      </li>
      <li>
        <Paragraph text="landing.sections.about-us.list.1" />
      </li>
      <li>
        <Paragraph text="landing.sections.about-us.list.2" />
      </li>
      <li>
        <Paragraph text="landing.sections.about-us.list.3" />
      </li>
      <li>
        <Paragraph text="landing.sections.about-us.list.4.item" />
        <List>
          <li>
            <Paragraph text="landing.sections.about-us.list.4.sublist.0" />
          </li>
          <li>
            <Paragraph text="landing.sections.about-us.list.4.sublist.1" />
          </li>
          <li>
            <Paragraph text="landing.sections.about-us.list.4.sublist.2" />
          </li>
        </List>
      </li>
    </List>
  </Section>
);

export default LandingAboutUs;
